import { useTranslatedArray } from '@/hooks/use-translated-array';
import { transformObjectToQuery } from '@/utility/common.utility';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Baths,
  Beds,
  Types,
  buyPriceOptions,
  leasePriceOptions,
} from './constants';
// import { SearchResultItem } from '@/hooks/use-search-result';
import { AppObject } from '@/types';
import { useRouter } from 'next/router';
import { useSearchLookup } from './useSearchLookup';

interface Filter {
  // location: SearchResultItem | null;
  neighborhood: string;
  district: string;
  saleType: string;
  propertyType: string;
  beds: string;
  baths: string;
  budget: string;
}

export default function useSearchBox() {
  const router = useRouter();
  const routerRef = useRef(router);
  routerRef.current = router;

  const [filter, setFilter] = useState<Filter>({
    saleType: 'sale',
    baths: '',
    beds: '',
    // location: null,
    budget: '',
    propertyType: '',
    neighborhood: '',
    district: '',
  });

  const filterRef = useRef(filter);
  filterRef.current = filter;

  const [invalidSearchError, setInvalidSearchError] = useState(false);
  const [loading, setLoading] = useState(false);

  const tType = useTranslatedArray(Types, 'label');
  const tBeds = useTranslatedArray(Beds, 'label');
  const tBaths = useTranslatedArray(Baths, 'label');
  const tBudget = useTranslatedArray(
    filter.saleType === 'lease' ? leasePriceOptions : buyPriceOptions,
    'label'
  );

  const searchLookups = useSearchLookup();

  const tBuildingTypes = useTranslatedArray(
    searchLookups.propertyTypes,
    'label'
  );

  const lookups = useMemo(() => {
    return {
      saleType: tType,
      propertyType: tBuildingTypes,
      beds: tBeds,
      baths: tBaths,
      budget: tBudget,
      district: searchLookups.districts,
      neighborhood: searchLookups.neighborhoods.filter(
        (x) => !filter.district || x.district === filter.district
      ),
    };
  }, [
    tType,
    tBuildingTypes,
    tBeds,
    tBaths,
    tBudget,
    searchLookups,
    filter.district,
  ]);

  useEffect(() => {
    if (!filter.neighborhood) {
      return;
    }

    const item = lookups.neighborhood.find(
      (x) => x.value === filter.neighborhood
    );

    if (!item) {
      setFilter((prev) => ({ ...prev, neighborhood: '' }));
      return;
    }

    // if (!filterRef.current.district) {
    //   setFilter((prev) => ({ ...prev, district: item.district }));
    // }
  }, [lookups.neighborhood, filter.neighborhood]);

  const updateFilter = useCallback((value: Partial<Filter>) => {
    setFilter((prev) => ({ ...prev, ...value }));
  }, []);

  useEffect(() => {
    updateFilter({ budget: '' });
  }, [filter.saleType, updateFilter]);

  const handleSearch = useCallback(() => {
    if (Object.values(filter).filter(Boolean).length < 2) {
      setInvalidSearchError(true);
      setTimeout(() => {
        setInvalidSearchError(false);
      }, 1000);
      return;
    }

    setLoading(true);

    const query: AppObject = {
      type: filter.saleType,
      propertyType: filter.propertyType,
    };

    // if (filter.location) {
    //   if (filter.location.type === 'city') {
    //     query.city = filter.location.name;
    //   } else if (filter.location.type === 'district') {
    //     query.district = filter.location.name;
    //   } else if (filter.location.type === 'neighborhood') {
    //     query.neighborhood = filter.location.name;
    //   }
    // }

    if (filter.neighborhood) {
      query.neighborhood = filter.neighborhood;
    }

    if (filter.district) {
      query.district = filter.district;
    }

    if (filter.beds) {
      const parsedValue = Number(filter.beds.replace(/\+/g, ''));
      query.minBeds = parsedValue;
      if (!filter.beds.endsWith('+')) {
        query.maxBeds = parsedValue;
      }
    }

    if (filter.baths) {
      const parsedValue = Number(filter.baths.replace(/\+/g, ''));
      query.minBaths = parsedValue;
      if (!filter.baths.endsWith('+')) {
        query.maxBaths = parsedValue;
      }
    }

    if (filter.budget) {
      const budget = lookups.budget.find((x) => x.value === filter.budget);

      if (budget) {
        query.minPrice = budget.min;
        query.maxPrice = budget.max;
      }
    }

    const path = `/properties?${transformObjectToQuery(query)}`;

    routerRef.current.push(path);
  }, [filter, lookups.budget]);

  return {
    filter,
    updateFilter,
    lookups,
    loading,
    handleSearch,
    invalidSearchError,
  };
}
