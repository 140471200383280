import { ContactModal } from '@/components/modals';
import { useState } from 'react';
import { useAgentInfo } from '@/hooks/use-agent-info';
import SearchBoxContainer from '@/module/home/SearchBox/SearchBoxContainer';
import { RecentProperties } from '@/components/recent-properties';
import Listings from '@/module/home/Listings';
import { useTranslation } from '@/hooks/use-translation';

export default function Home() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data: agentInfo } = useAgentInfo();

  return (
    <div>
      <section
        // className="hero"
        className="w-full bg-no-repeat bg-cover -mt-36 flex items-center bg-center pt-40 pb-20"
        style={{ backgroundImage: "url('/img/knowus/bg.png')" }}
      >
        <div className="px-8 w-full flex justify-center items-center">
          <div className="w-192">
            <h1 className="text-2xl text-center font-bold text-black mb-8">
              {t('Find your perfect place')}
            </h1>
            <SearchBoxContainer />
          </div>
        </div>
      </section>

      <section className="container px-8 my-8 mt-4">
        <Listings />
      </section>

      <section className="container px-8">
        <RecentProperties />
      </section>

      {/* <section className="container mx-auto mt-4 mb-4">
        <div className="sk-ww-google-reviews" data-embed-id="126330"></div>
        <SociableKitScript src="https://www.sociablekit.com/app/embed/google-reviews/widget.js" />
      </section> */}

      {/* <section>
        <div className="container mx-auto">
          <div className="instagram-section">
            <h2 className="text-center font-semibold text-xl md:text-2xl">
              INSTAGRAM <span>&#64;{InfoJson.instagramId}</span>
            </h2>
            <div className="flex items-center flex-wrap justify-center">
              {InstagramPosts.map((el) => (
                <div className="group relative mt-3" key={el.image}>
                  <div className="relative w-36 md:w-60 h-40 md:h-60">
                    <Image src={el.image} layout="fill" alt="Instagram Post" />
                  </div>
                  <div className="overlay absolute inset-0 bg-black bg-opacity-20 z-10 group-hover:scale-100 scale-0 transform duration-300 flex items-center justify-center">
                    <a
                      href={`https://www.instagram.com/${InfoJson.instagramId}${el.path}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="relative h-10 w-10">
                        <Image
                          src="/img/instagram.png"
                          layout="fill"
                          alt="Instgram Icon"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}
      <ContactModal
        open={open}
        toggleModal={() => setOpen((prev) => !prev)}
        agentInfo={agentInfo}
      />
    </div>
  );
}
