import NetworkService from '@/services/network.service';
import { useEffect, useState } from 'react';
import Info from '@/config/info.json';
import Config from '@/config';

export interface AgentInfo {
  id: string | null;
  name: string;
  phone: string;
  address: string;
  email: string;
  image: string;
  addressParts: string[];
}

export function useAgentInfo(id?: string) {
  const [loading, setLoading] = useState(true);
  const [agentInfo, setAgentInfo] = useState<AgentInfo | null>(null);

  useEffect(() => {
    if (!id) {
      setLoading(false);
      setAgentInfo({
        name: Info.name,
        address: Info.address,
        email: Info.email,
        id: null,
        phone: Info.contactNo,
        image: Info.ownerImage,
        addressParts: [
          Info.footeraddress1,
          Info.footeraddress2,
          Info.footeraddress3,
        ],
      });
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const client = new NetworkService();
        const response = await client.get<AgentInfo>(`/api/agent/${id}`);
        setLoading(false);
        setAgentInfo({
          ...response,
          image: response.image ? `${Config.CDN_URL}/${response.image}` : '',
          address: response.address?.replace(/\\n/g, ''),
          addressParts:
            response.address?.split('\n').map((x) => x.trim()) || [],
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
        setAgentInfo({
          name: Info.name,
          address: Info.address,
          email: Info.email,
          id: null,
          phone: Info.contactNo,
          image: Info.ownerImage,
          addressParts: [
            Info.footeraddress1,
            Info.footeraddress2,
            Info.footeraddress3,
          ],
        });
      }
    })();
  }, [id]);

  return {
    loading,
    data: agentInfo,
  };
}
