import { useTranslation } from '@/hooks/use-translation';
import TabBar from './TabBar';
// import SearchBox from './SearchBox';
import Dropdown from './Dropdown';
import useSearchBox from './useSearchBox';
import clsx from 'clsx';
import Spinner from '@/icons/spinner';

const SearchBoxContainer: React.FC = () => {
  const { t } = useTranslation();
  const {
    filter,
    updateFilter,
    lookups,
    loading,
    handleSearch,
    invalidSearchError,
  } = useSearchBox();

  return (
    <div className={clsx('w-full', invalidSearchError && 'animate-shake')}>
      <div className="flex">
        <TabBar
          value={filter.saleType}
          onChange={(value) => updateFilter({ saleType: value })}
          tabs={lookups.saleType}
        />
      </div>
      <div className="bg-white w-full rounded-2xl shadow-2xl">
        {/* <SearchBox
          selectedItem={filter.location}
          onSelect={(value) => updateFilter({ location: value })}
        />

        <div className="w-full px-2">
          <div className="w-full border-b border-gray-50" />
        </div> */}
        <div className="relative flex flex-col md:flex-row">
          <Dropdown
            label={t('District')}
            value={filter.district}
            onChange={(value) => updateFilter({ district: value })}
            options={lookups.district}
            // className="rounded-tl-none"
            style={{ minWidth: '16rem' }}
          />
          <div className="flex flex-col items-center justify-center px-2 md:px-0">
            <div className="w-full h-1 md:w-1 md:h-6 border-b md:border-b-0 md:border-l border-gray-100" />
          </div>
          <Dropdown
            label={t('Neighborhood')}
            value={filter.neighborhood}
            onChange={(value) => updateFilter({ neighborhood: value })}
            options={lookups.neighborhood}
            style={{ minWidth: '16rem' }}
          />
        </div>
        <div className="w-full px-2">
          <div className="w-full border-b border-gray-50" />
        </div>
        <div className="relative flex flex-col md:flex-row">
          <Dropdown
            label={t('Property Type')}
            value={filter.propertyType}
            onChange={(value) => updateFilter({ propertyType: value })}
            options={lookups.propertyType}
            style={{ minWidth: '12rem' }}
          />
          <div className="flex flex-col items-center justify-center px-2 md:px-0">
            <div className="w-full h-1 md:w-1 md:h-6 border-b md:border-b-0 md:border-l border-gray-100" />
          </div>
          <Dropdown
            label={t('Beds')}
            value={filter.beds}
            onChange={(value) => updateFilter({ beds: value })}
            options={lookups.beds}
            style={{ minWidth: '8rem' }}
          />
          <div className="flex flex-col items-center justify-center px-2 md:px-0">
            <div className="w-full h-1 md:w-1 md:h-6 border-b md:border-b-0 md:border-l border-gray-100" />
          </div>
          <Dropdown
            label={t('Baths')}
            value={filter.baths}
            onChange={(value) => updateFilter({ baths: value })}
            options={lookups.baths}
            style={{ minWidth: '8rem' }}
          />
          <div className="flex flex-col items-center justify-center px-2 md:px-0">
            <div className="w-full h-1 md:w-1 md:h-6 border-b md:border-b-0 md:border-l border-gray-100" />
          </div>
          <Dropdown
            label={t('Budget')}
            value={filter.budget}
            onChange={(value) => updateFilter({ budget: value })}
            options={lookups.budget}
            style={{ minWidth: '14rem' }}
          />
        </div>
        <div className="w-full px-2">
          <div className="w-full border-b border-gray-50" />
        </div>
        <div className="w-full p-4">
          <button
            className={clsx(
              'w-full py-4 bg-primary-400 text-white rounded-lg flex justify-center',
              !loading && 'hover:bg-primary-600'
            )}
            disabled={loading}
            onClick={handleSearch}
          >
            {loading && <Spinner className="w-6 h-6 spin" />}
            {!loading && t('Search')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBoxContainer;
