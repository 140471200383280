import { ClickAway } from '@/components/ui/click-away';
import { DropdownData } from '@/components/ui/dropdown-container';
import ArrowDown from '@/icons/arrow-down';
import IconClearCircle from '@/icons/icon-clear-circle';
import clsx from 'clsx';
import { useMemo, useState } from 'react';

interface DropdownProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: DropdownData[];
  className?: string;
  style?: React.CSSProperties;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  value,
  onChange,
  options,
  className,
  style,
}) => {
  const [open, setOpen] = useState(false);

  const selectedItem = useMemo(() => {
    return options.find((item) => item.value === value);
  }, [options, value]);

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAway
      onClickAway={handleClickAway}
      className={clsx(
        'relative h-16 min-w-36 bg-white rounded-2xl transition-all',
        open && 'shadow-2xl scale-105 rounded-b-none z-50',
        className
      )}
      style={style}
    >
      <div
        className={clsx(
          'absolute left-0 right-0 top-0 bottom-0 flex-1 p-4 flex items-center gap-2 justify-between cursor-pointer',
          'text-gray-300 border border-b-0 rounded-2xl rounded-b-none',
          open && 'border-gray-50',
          !open && 'border-white'
        )}
        onClick={() => setOpen(!open)}
      >
        <div>
          <div
            className={clsx(
              'absolute flex items-center h-full top-0',
              value && 'left-4 text-xs scale-90 -translate-x-1 -translate-y-3',
              value ? 'text-primary-900' : 'text-gray-300'
            )}
          >
            {label}
          </div>
          <div className="translate-y-2 text-primary-900 text-sm whitespace-nowrap">
            {selectedItem?.label || value}
          </div>
        </div>
        <div className="flex gap-1">
          {value && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onChange('');
              }}
              className="text-red-300 hover:text-red-400"
            >
              <IconClearCircle className="w-4 h-4" />
            </button>
          )}
          <ArrowDown className="w-4 h-4 text-current" />
        </div>
      </div>
      <ul
        className={clsx(
          'absolute left-0 right-0 top-full bg-white rounded-2xl rounded-t-none z-50 transition-all overflow-auto',
          open && 'border border-gray-50'
        )}
        style={{ maxHeight: open ? '16rem' : '0' }}
      >
        {options.map((item) => (
          <li
            key={item.value}
            className={clsx(
              'w-full cursor-pointer text-sm whitespace-nowrap',
              'px-4 py-3',
              'hover:bg-gray-100'
            )}
            onClick={() => {
              onChange(item.value);
              setOpen(false);
            }}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </ClickAway>
  );
};

export default Dropdown;
