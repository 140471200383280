import clsx from 'clsx';

interface TabButtonProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

const TabButton: React.FC<TabButtonProps> = ({ label, active, onClick }) => {
  return (
    <button
      className={clsx(
        'py-2 px-8 text-primary-900',
        active && 'bg-white rounded-2xl rounded-b-none'
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

interface TabBarProps {
  value: string;
  onChange: (value: string) => void;
  tabs: {
    label: string;
    value: string;
  }[];
}

const TabBar: React.FC<TabBarProps> = ({ value, onChange, tabs }) => {
  return (
    <div className="ml-8 bg-primary-50 rounded-2xl rounded-b-none shadow-2xl">
      <div className="flex">
        {tabs.map((tab) => (
          <TabButton
            key={tab.value}
            label={tab.label}
            active={tab.value === value}
            onClick={() => onChange(tab.value)}
          />
        ))}
      </div>
    </div>
  );
};

export default TabBar;
