import { DropdownData } from '@/components/ui/dropdown-container';
import {
  Baths as _Baths,
  Beds as _Beds,
} from '@/components/listing/search-bar/data';

export const Types: DropdownData[] = [
  {
    label: 'Buy',
    value: 'sale',
  },
  {
    label: 'Rent',
    value: 'lease',
  },
];

export const Baths = _Baths.filter((x) => x.value);
export const Beds = _Beds.filter((x) => x.value);

export const buyPriceOptions: (DropdownData & { min: number; max: number })[] =
  [
    {
      label: 'Up to $100,000',
      value: 'b-100000',
      min: null,
      max: 100000,
    },
    {
      label: '$100,000 - $200,000',
      value: 'b-200000',
      min: 100001,
      max: 200000,
    },
    {
      label: '$200,000 - $500,000',
      value: 'b-500000',
      min: 200001,
      max: 500000,
    },
    {
      label: '$500,000 - $1,000,000',
      value: 'b-1000000',
      min: 500001,
      max: 1000000,
    },
    {
      label: 'Above $1,000,000',
      value: 'b-1000001',
      min: 1000001,
      max: null,
    },
  ];

export const leasePriceOptions: (DropdownData & {
  min: number;
  max: number;
})[] = [
  {
    label: 'Upto $1,000',
    value: 'r-1000',
    min: null,
    max: 1000,
  },
  {
    label: '$1,000 - $2,000',
    value: 'r-2000',
    min: 1001,
    max: 2000,
  },
  {
    label: '$2,000 - $5,000',
    value: 'r-5000',
    min: 2001,
    max: 5000,
  },
  {
    label: '$5,000 - $10,000',
    value: 'r-10000',
    min: 5001,
    max: 10000,
  },
  {
    label: 'Above $10,000',
    value: 'r-10001',
    min: 10001,
    max: null,
  },
];
