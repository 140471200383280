import { PropertyCard, PropertyCardLoader } from '@/components/property';
import { useTranslation } from '@/hooks/use-translation';
import { Property } from '@/types';
import { transformProperty } from '@/utility';
import Link from 'next/link';
import { useEffect, useState } from 'react';

const Listings: React.FC = () => {
  const { t } = useTranslation();
  const [properties, setProperties] = useState<Property[]>([]);

  useEffect(() => {
    const getProperties = async () => {
      const response = await fetch(
        '/api/repliers/listings?hasImages=true&resultsPerPage=20&type=sale'
      );
      const data = await response.json();
      setProperties(data.listings.map(transformProperty));
    };
    getProperties();
  }, []);

  return (
    <div>
      {/* <h3 className="mb-4 text-lg font-bold">Newly listed properties</h3> */}
      <div className="flex mb-2">
        <div className="flex px-6 py-1 bg-primary-500 text-white rounded">
          {t('Buy')}
        </div>
        <Link href="/properties?type=lease">
          <a className="flex px-6 py-1 hover:bg-primary-200 rounded">
            {t('Rent')}
          </a>
        </Link>
      </div>
      {properties.length ? (
        <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-10 gap-8">
          {properties.map((el) => (
            <PropertyCard property={el} key={el.id} showFavIcon />
          ))}
        </div>
      ) : (
        <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {new Array(20).fill(0).map((_, index) => (
            <PropertyCardLoader key={String(index)} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Listings;
